import { mapActions } from 'vuex';

export default {
  data() {
    return {
      activeOrderId: '',
    };
  },
  beforeMount() {
    this.getActiveOrderStore()
      .then((data) => {
        if (data) {
          data.forEach((item) => {
            if (item.product === 'cache_online') {
              this.activeOrderId = item.orderId;
            }
          });
        }
      }).catch((err) => console.log(err));
  },
  methods: {
    ...mapActions('cashForm', [
      'getActiveOrderStore',
    ]),
  },
};
