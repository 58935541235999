<template>
  <div>
    <v-scale-transition
      v-if="isAuthenticated"
      mode="out-in"
    >
      <v-hover
        v-slot="{ hover }"
      >
        <v-card
          rounded="xl"
          :elevation="hover ? 0 : 0"
          class="my-5"
          color="background lighten-1"
        >
          <notification
            v-if="errorNotification"
            type="error"
          >
            Щось пішло не так. Спробуйте ще раз
          </notification>

          <v-card-text class="py-md-5 pa-lg-10 pt-xl-7 pb-xl-16 px-xl-16 border-green box-shadow">
            <v-row
              no-gutters
              class="col-12 pa-0"
            >
              <stepper class="col-12" />
            </v-row>

            <v-row class="mt-4">
              <v-card-title class="text--text text-lg-h5">
                Подача заявки
              </v-card-title>
            </v-row>
            <v-row class="credit-calculator py-2">
              <v-col
                lg="6"
                md="6"
                cols="12"
                class="px-6 pt-3 pr-lg-7"
              >
                <v-row>
                  <v-subheader class="col-12 subheader">
                    <span class="text--text">
                      Строк кредиту, міс.
                    </span>
                    <v-btn
                      class="term-and-sum-wrapper"
                      color="warning"
                    >
                      {{ creditLength }}
                    </v-btn>
                  </v-subheader>
                  <v-select
                    :items="segmentationDataArr"
                    item-text="loanTerm"
                    label="Строк кредиту, міс."
                    item-value="loanTerm"
                    color="orange"
                    @change="calculateSegmentationData"
                  ></v-select>
                </v-row>

                <v-row>
                  <v-subheader class="col-12 subheader">
                    <span class="text--text">
                      Сума кредиту
                    </span>
                    <v-btn
                      class="term-and-sum-wrapper"
                      color="success"
                    >
                      {{ creditValue }}
                    </v-btn>
                  </v-subheader>
                  <v-slider
                    v-model="creditValue"
                    :step="currentSegmentationObj.amount_step"
                    :min="currentSegmentationObj.minimum_amount"
                    :max="currentSegmentationObj.maximum_amount"
                    thumb-color="green"
                    track-color="darkGray"
                    class="ml-n1"
                    color="green"
                    :label="'' + creditValue + ''"
                    inverse-label
                    @change="calculateMonthlyPayment"
                  />
                </v-row>

                <v-row
                  no-gutters
                  class="text--text"
                >
                  <p class="subtitle-1 col-12 mb-0">
                    Щомісячний платіж, грн.
                  </p>
                  <p class="text-h5 text-lg-h4 font-weight-medium mt-1">
                    {{ monthlyPayment | sumFormatter }}
                  </p>
                </v-row>
              </v-col>
              <v-col
                md="6"
                cols="12"
                class="pl-lg-7 text--text"
              >
                <v-divider />
                <v-card-title class="pl-2">
                  Попередній розрахунок
                </v-card-title>
                <v-divider />
                <v-row class="mt-3 mb-3 pl-2">
                  <v-col>Щомісячне погашення кредиту</v-col>
                  <v-col class="text-end pr-5">
                    {{ monthlyPaymentByBodyOfLoan | sumFormatter }}
                  </v-col>
                </v-row>
                <v-divider />
                <v-row class="mt-3 mb-3 pl-2">
                  <v-col>Щомісячні відсотки + комісія</v-col>
                  <v-col class="text-end pr-5">
                    {{ monthlyCommissionPayment }}
                  </v-col>
                </v-row>
                <v-divider />
                <v-row class="mt-3 mb-3 pl-2">
                  <v-col>Щомісячний платіж</v-col>
                  <v-col class="text-end pr-5">
                    {{ monthlyPayment | sumFormatter }}
                  </v-col>
                </v-row>
                <v-divider />
                <v-row class="mt-3 mb-3 pl-2">
                  <v-col>Термін кредиту</v-col>
                  <v-col class="text-end pr-5">
                    {{ creditLength }}
                  </v-col>
                </v-row>
                <v-divider />
                <v-row class="mt-3 mb-3 pl-2">
                  <v-col>Дострокове погашення без комісії</v-col>
                </v-row>
                <v-divider />
                <v-row class="mt-3 mb-3 pl-2">
                  <v-col>
                    <a
                      href="https://cdn-do.sfactor.com.ua/static/documents/2.%20%D0%9F%D1%83%D0%B1%D0%BB%D1%96%D1%87%D0%BD%D0%B0%20%D0%BF%D1%80%D0%BE%D0%BF%D0%BE%D0%B7%D0%B8%D1%86%D1%96%D1%8F%20%D0%BD%D0%B0%20%D0%B0%D0%BD%D0%B0%D0%BB%D0%BE%D0%B3.pdf"
                      class="hover-link text-decoration-none text--text font-weight-medium"
                      target="_blank"
                    >
                      Умови кредитування
                    </a>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row
              no-gutters
              class="mt-7 pb-3 position-relative"
            >
              <block-preloader
                v-if="blockPreloaderIsVisible"
              />
              <v-btn
                :class="{ 'visibility-hidden': blockPreloaderIsVisible }"
                rounded
                :block="$vuetify.breakpoint.xsOnly"
                color="success"
                class="px-5 font-weight-bold mx-auto"
                @click="submit"
              >
                Подати заявку
              </v-btn>
            </v-row>
          </v-card-text>
        </v-card>
      </v-hover>
    </v-scale-transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Stepper from '@/components/common/Stepper';
import Notification from '@/components/common/Notification';
import getActiveOrderIdMixin from '@/mixins/getActiveOrderIdMixin';
import blockPreloaderMixin from '@/mixins/blockPreloaderMixin';
import errorNotificationMixin from '@/mixins/errorNotificationMixin';
import BlockPreloader from '@/components/common/BlockPreloader';
import RouteNamesService from '@/services/route-names-service';
import pageLoaderMixin from '../mixins/pageLoaderMixin';

export default {
  name: 'CreditCalculator',
  title: 'Подача заявки',
  components: {
    BlockPreloader,
    Stepper,
    Notification,
  },
  mixins: [getActiveOrderIdMixin, blockPreloaderMixin, errorNotificationMixin, pageLoaderMixin],
  data() {
    return {
      currentProductCode: 'cache_online',
      oneTimeCommission: 0,
      rate: 0,
      monthlyPayment: 0,
      minimumAmount: 0,
      monthlyPaymentByBodyOfLoan: 0,
      monthlyCommissionPayment: 0,
      currentSegmentationObj: {},
      segmentationDataArr: [],
      defaultSegmentationDataArr: [{
        amountStep: 1000,
        loanRatePercents: 0.01,
        loanTerm: 12,
        maxPaymentMonthValue: 3000,
        maximumAmount: 50000,
        minimumAmount: 1000,
        monthFeePercents: 0.04,
        onetimeFeePercents: 0.1,
        penaltyPercent: 200,
        privilegePeriod: 0,
      }],
    };
  },
  computed: {
    ...mapGetters('auth', [
      'isAuthenticated',
    ]),
    creditLength: {
      get() {
        return this.$store.state.cashForm.creditData.creditLength;
      },

      set(value) {
        this.$store.commit(
          'cashForm/UPDATE_FORM_DATA',
          {
            parentObjName: 'creditData',
            attribute: 'creditLength',
            value: `${value}`,
          },
        );
      },
    },
    creditValue: {
      get() {
        return this.$store.state.cashForm.creditData.creditValue;
      },

      set(value) {
        this.$store.commit(
          'cashForm/UPDATE_FORM_DATA',
          {
            parentObjName: 'creditData',
            attribute: 'creditValue',
            value: `${value}`,
          },
        );
      },
    },
  },
  beforeMount() {
    this.updateStep(3);
    this.getSegmentation();
  },
  mounted() {
    this.getStateStore(this.currentProductCode);
  },
  methods: {
    ...mapActions('cashForm', [
      'updateStep',
      'getStateStore',
      'getSegmentationStore',
      'updateExistingOrderStore',
    ]),
    sortSegmentationDataArr() {
      this.segmentationDataArr.sort((a, b) => a.loan_term - b.loan_term);
    },
    findCurrentObj(loanTerm) {
      return this.segmentationDataArr.find((item) => item.loan_term === loanTerm);
    },
    calculateSegmentationData(loanTerm) {
      this.currentSegmentationObj = this.findCurrentObj(loanTerm);
      this.oneTimeCommission = this.currentSegmentationObj.onetimeFeePercents / 100;
      this.rate = this.currentSegmentationObj.loanRatePercents / 100;
      this.creditLength = loanTerm;
      // eslint-disable-next-line no-unused-expressions
      this.currentSegmentationObj.minimumAmount !== this.minimumAmount
        ? this.creditValue = this.currentSegmentationObj.minimumAmount : null;
      this.minimumAmount = this.currentSegmentationObj.minimumAmount;
      this.calculateMonthlyPayment();
    },
    getSegmentation() {
      const interval = setInterval(() => {
        if (this.isAuthenticated) {
          this.getSegmentationStore()
            .then((data) => {
              this.segmentationDataArr = [...data];
              this.sortSegmentationDataArr();
              // eslint-disable-next-line prefer-destructuring
              this.currentSegmentationObj = this.segmentationDataArr[0];
              this.calculateSegmentationData(this.currentSegmentationObj.loan_term);
              this.hidePagePreloader();
            });
          clearInterval(interval);
        }
      }, 1000);
    },
    calculateMonthlyPaymentByBodyOfLoan() {
      // BP = S*(1+ComOne) / Tenor
      // eslint-disable-next-line no-mixed-operators
      this.monthlyPaymentByBodyOfLoan = this.creditValue * (1 + this.oneTimeCommission)
        // eslint-disable-next-line no-mixed-operators
        / this.creditLength;
      this.monthlyPaymentByBodyOfLoan = this.monthlyPaymentByBodyOfLoan.toFixed(2);
    },
    calculateMonthlyCommissionPayment() {
      // IP = (S *(1+ComOne))*(R/12) + (S*(1+ComOne))*ComMonth
      // eslint-disable-next-line no-mixed-operators
      this.monthlyCommissionPayment = (this.creditValue * (1 + this.oneTimeCommission))
        * (this.rate / 12);
      /* this.monthlyCommissionPayment = (this.creditValue * (1 + this.oneTimeCommission))
        * (this.rate / 12) + (this.creditValue * (1 + this.oneTimeCommission))
        * this.monthlyCommission; */
      this.monthlyCommissionPayment = this.monthlyCommissionPayment.toFixed(2);
    },
    calculateMonthlyPayment() {
      this.calculateMonthlyPaymentByBodyOfLoan();
      this.calculateMonthlyCommissionPayment();
      this.monthlyPayment = +this.monthlyPaymentByBodyOfLoan + +this.monthlyCommissionPayment;
      this.monthlyPayment = this.monthlyPayment.toFixed(2);
    },
    getObjectToSend() {
      return {
        personalData: this.$store.state.cashForm.personalData,
        creditData: this.$store.state.cashForm.creditData,
        contactPerson: this.$store.state.cashForm.contactPerson,
        additionalInfo: this.$store.state.cashForm.additionalInfo,
        documentData: this.$store.state.cashForm.documentData,
        address: this.$store.state.cashForm.address,
        bankCard: this.$store.state.cashForm.bankCard,
      };
    },
    submit() {
      this.toggleBlockPreloader(true);
      const obj = this.getObjectToSend();
      this.updateExistingOrderStore({
        orderId: this.activeOrderId,
        objToSend: obj,
      })
        .then(() => {
          this.toggleBlockPreloader(false);
          this.$router.push({ name: RouteNamesService.userCredits });
        }).catch(() => {
          this.toggleBlockPreloader(false);
          this.toggleErrorMessage();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.credit-calculator {
  color: #fff !important;

  @media screen and (min-width: 960px) {
    flex-wrap: nowrap;
  }
}

.subheader {
  color: #fff !important;
}

.term-and-sum-wrapper {
  padding: 6px 10px !important;
  height: auto !important;
  min-width: auto !important;
  margin-left: 10px !important;
  cursor: auto;
}
</style>
